const OngoingProgramDetails = [
  {
    id: 1,
    title: " BEGINNERS 41 DAYS GROUP MEDITATION.",
    date: "EVERY MORNING ",
    time: "5:00 AM - 6:15 AM PST",
    img: require("./images/MRNG_41_Days.jpeg"),
    zoom_link:
      "https://us02web.zoom.us/j/87976706115?pwd=RFZTSTEzbzM5L2N2Q2ZiMjMyMmNkQT09",
    zoom_meeting_id: "879 7670 6115",
    zoom_passcode: "555 555",
    youtube_link: "https://www.youtube.com/@PMCUSA",
  },
  // {
  //   id: 5,
  //   title: " KIDS MEDITATION.",
  //   date: "EVERY DAY FROM FEB - 18 ",
  //   time: "8:45 pm - 9.15 pm EST",
  //   img: require("./images/PMCKIDS.jpeg"),
  //   zoom_link:
  //     "https://us02web.zoom.us/j/84985049379?pwd=MmFReFA1Q3Q3eUFRMlZTOWN1cUsxdz09",
  //   zoom_meeting_id: "",
  //   zoom_passcode: "",
  //   youtube_link: "https://www.youtube.com/@PMCUSA",
  // },
  // {
  //   id: 4,
  //   title: "SANKALPA DHYAN FOR RALEIGH PYRAMID PROJECT",
  //   date: "FEB 8th to MARCH 18th",
  //   time: "9pm to 10pm EST",
  //   img: require("./images/Raleigh_41days.jpeg"),
  //   zoom_link:
  //     "https://us02web.zoom.us/j/85876011955?pwd=eTNCMFlGUHIzUnhUTG4zZTZHZVRWUT09",
  //   zoom_meeting_id: "",
  //   zoom_passcode: "",
  //   youtube_link: "https://www.youtube.com/@PMCUSA",
  // },
  {
    id: 3,
    title: "DAILY MORNING MEDITATIONS.",
    date: "EVERY DAY MORNING",
    time: "5:30 AM EST- 6:30 AM EST",
    img: require("./images/Enlightenment.jpeg"),
    zoom_link:
      "https://us02web.zoom.us/j/83299568390?pwd=RWV1eGNoS2J2TENxaXdQS1p0Y3NnQT09",
    zoom_meeting_id: "",
    zoom_passcode: "",
    youtube_link: "https://www.youtube.com/@PMCUSA",
  },
  // {
  //   id: 2,
  //   title: "MEDITATION INPROGRESS.",
  //   date: "EVERY DAY FROM FEB 1st to FEB 21st ",
  //   time: "9 pm - 10 pm EST",
  //   img: require("./images/Meditation_InProgress.jpeg"),
  //   zoom_link:
  //     "https://us06web.zoom.us/j/84222169270?pwd=gPXqda2lbyY4no3aINvj4nOkCpOkud.1",
  //   zoom_meeting_id: "",
  //   zoom_passcode: "",
  //   youtube_link: "https://www.youtube.com/@PMCUSA",
  // },

  // {
  //   id: 2,
  //   title: "PSSM MEDITATION SUMMIT - Tennessee",
  //   date: "JANUARY 27th 2024 ",
  //   time: "8:00pm to 8:45pm CST",
  //   img: require("./images/Monthly_MMS_Jan.jpg"),
  //   zoom_link:
  //     "https://us06web.zoom.us/j/81661521261?pwd in=KzlSc0ZUSDZDemR6dEl2WVo5VHdlQT09",
  //   zoom_meeting_id: "816 6152 1261",
  //   zoom_passcode: "111111",
  //   youtube_link: "https://youtube.com/live/f1P5AepH3no?feature=share",
  // },
  // {
  //   id: 3,
  //   title: "SWADHYAYA CHALLENGE: Book Readings",
  //   date: "Every Mondays 4PM ",
  //   time: "8:00pm to 8:45pm CST",
  //   img: require("./images/Monthly_MMS_Jan.jpg"),
  //   zoom_link:
  //     "https://us06web.zoom.us/j/81661521261?pwd in=KzlSc0ZUSDZDemR6dEl2WVo5VHdlQT09",
  //   zoom_meeting_id: "816 6152 1261",
  //   zoom_passcode: "111111",
  //   youtube_link: "https://youtube.com/live/f1P5AepH3no?feature=share",
  // },
];

const UpComingOnlinePrograms = [
  // {
  //   id: 2,
  //   title: " SWADHYA CHALLENGE",
  //   date: "FEB - 13",
  //   time: "8:30 pm - 9.30 pm EST",
  //   img: require("./images/SwadhyaChallenge.jpeg"),
  //   zoom_link: "",
  //   zoom_meeting_id: "",
  //   zoom_passcode: "",
  //   youtube_link: "https://www.youtube.com/@PMCUSA",
  // },
];

const RecentProgramDetails = [
  {
    id: 5,
    title: "PSSM MEDITATION SUMMIT",
    date: "March 23rd",
    time: "9AM to 4PM EST",
    img: require("./images/PSSM_MINI_GA2.jpeg"),
    venu_location: "Banjara Banquets, 1656 Buford Hwy, Cumming, GA 30041",
    registration_link: "http://tinyurl.com/pssm-atl",
    youtube_link: "https://www.youtube.com/watch?v=99BMHJOCbCY",
  },
  {
    id: 4,
    title: "PSSM MEDITATION SUMMIT",
    date: " February 24th",
    time: "9AM to 4PM EST",
    img: require("./images/NJ_MMST.jpeg"),
    venu_location: "Ved Mandir, 1 Ved Mandir Dr, Milltown, NJ 08850",
    registration_link:
      "https://docs.google.com/forms/d/e/1FAIpQLScjRc7GlGICYRzGbxRBwZo3QXKdcpXOvpWK6RiELMh6UxFdgA/viewform",
    youtube_link: "https://www.youtube.com/watch?v=A6AruSLMYT4",
  },
  {
    id: 3,
    title: "PSSM MEDITATION SUMMIT",
    date: "27th January 2024 - Saturday",
    time: "10 AM to 4 PM CST",
    img: require("./images/Monthly_MMS_Jan.jpg"),
    // venu_location:
    //   "Nolensville First United Methodist Church, 7316 Nolensville Rd, Nolensville TN 37135",
    // registration_link:
    //   "https://docs.google.com/forms/d/e/1FAIpQLSdrSA5uFz5qQ6bBwT-_eAyIBrL2O7SZUTX6-xZAhM4l7RXzGg/viewform",
    youtube_link: "https://www.youtube.com/watch?v=XyKvcqvVlCM",
  },
  {
    id: 2,
    title: "Harmony Within - Workshop in Atlanta",
    date: "26th January 2024 - Friday",
    time: "6.30 PM to 8 PM EST",
    img: require("./images/SPT_Chandra_Sir.jpeg"),
    // venu_location:
    //   "Nine East Building (Behind Kroger) 1740 Grassland Parkway, Block 400 Alpharetta, Georgia 30004",
    // registration_link: "https://forms.gle/D7NhKtRrjX8eKxfG6",
    youtube_link: "https://www.youtube.com/watch?v=kUTAzZCdFH0",
  },
  {
    id: 1,
    title: "CHANGE WHAT YOU EAT CHANGE THE WORLD",
    date: "NOV 19th 2023 ",
    time: "8.30 PM to 10 PM EST",
    img: require("./images/gaurav.jpeg"),
    // zoom_link:
    //   "https://us02web.zoom.us/j/83299568390?pwd=SUJOZWsyUGE3ei9naEUvMTJUdHEwdz09",
    // zoom_meeting_id: "832 9956 8390",
    // zoom_passcode: "801942",
    youtube_link: "https://www.youtube.com/watch?v=8QSMI2Oy9Q4&t=3s",
  },
];

const InPersonProgramDetails = [
  {
    id: 1,
    title: "Yoga for unity & wellbeing",
    date: "June 15th",
    time: "9AM to 3PM CST",
    img: require("./images/Heartfullness.jpg"),
    venu_location: "Radha Krishna Temple , 1450 North Watters Road, Allen, TX 75013",
    registration_link: "http://Yoga4unitydallas.eventbrite.com",
    youtube_link: "https://www.youtube.com/channel/UCoG2o8WtvYh8sCS40pUFtCg"
  },
  {
    id: 2,
    title: "PSSM MEDITATION SUMMIT 2",
    date: "August 30th to September 2nd",
    time: "9AM to 4PM EST",
    img: require("./images/PSSM_MEGA_SUMMIT2.jpeg"),
    venu_location: "Raleigh, NC",
    registration_link: "https://bit.ly/MMS-PSSM",
    youtube_link: "https://www.youtube.com/@PMCUSA",
  },
  // PSSM_MEGA_SUMMIT2.jpeg
];

export {
  RecentProgramDetails,
  OngoingProgramDetails,
  InPersonProgramDetails,
  UpComingOnlinePrograms,
};
